<template>
   <v-row justify="center">
       <v-dialog v-model="shower" scrollable persistent max-width="500px">
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template>-->

      <v-card>
          <!-- {{$t('uploadfile.name')}} -->
        <v-card-title :color="color.theme">Upload Related Outbox</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- 
          :labelFileProcessingError="labelprocess"-->
          <br />
          <file-pond
            name="filepond"
            ref="pond"
            allow-multiple="true"
            allow-drop="true"
            class-name="my-pond"
            allowRevert="false"
            :label-idle="$t('uploadfile.clicktoupload')"
            v-bind:files="myFiles"
            maxFileSize="500MB"
            maxParallelUploads="1"
            v-on:init="handleFilePondInit"
            :server="{  process, revert,  restore, load, fetch }"
            :init="handleFilePondInit"
            :warning="handleWarning"
            :error="handleError"
            processFile
          />
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            :dark="color.darkTheme"
            @click="$emit('closedialogupload'), cleardata()"
            :disabled="createprogress"
          >{{$t('uploadfile.buttonclose')}}</v-btn>
          <!-- <v-btn color="primary" depressed @click="uploadfiles()" :loading="createprogress" :disabled="createprogress">อัปโหลดไฟล์</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-row> 
</template>
<script>
// Import FilePond
import vueFilePond, { setOptions } from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
// // Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import gbfGenerate from "@/globalFunctions/generateAuthorize";
export default {
  mixins: [validationMixin],
  validations: {
    foldername: { required }
  },
  components: { FilePond },
     props: ["show", "parentfolder"],
     data: function(){ 
      return { valid: true,
      files: [],
      labelprocess: "",
      foldername: "",
      createprogress: false,
      uploadPercentage: 0,
      uploadProcess: "",
      uploadColor: "light-blue",
      uploadIndeterminate: false,
      listuploadfile: [],
      existingFiles: [],
      myFiles: []
      };
    },
    computed: {
      ...mapState(['username', 'authorize','account_active',"color"]),
      ...mapState({processloader: 'loading'}),
      ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
    shower :{
        get() {
            if(this.show === true){
              this.$emit('closeDrag')
            }
            return this.show
        },
        set (value) {
            if (!value) {
               this.$emit('closedialogupload')
            }
        }
    },
  },
  methods:{
    async process(fieldName, file, metadata, load, error, progress, abort) {
      console.log("process start");
      this.$emit("checkclosedialog");
      this.createprogress = true;
      let file_duplicate = "create_copy";
      if (this.selectcopyortag === false) {
        // copy
        file_duplicate = "create_copy";
      } else {
        // tag
        file_duplicate = "create_tag_version";
      }
      // set data
      console.log(file);
      const formData = new FormData();
      // formData.append('file', file, file.name);
      formData.append("file", file);
      formData.append("size_file", file.size);
      formData.append("folder_id", this.parentfolder);
      formData.append("user_id", this.dataUsername);
      // formData.append("department_id", this.dataDepartmentAccessId);
      formData.append("department_id", this.departmentid);
      formData.append("account_id", this.dataAccountId);
      formData.append("file_duplicate", file_duplicate);
      formData.append("remark", this.remark || "");
      formData.append("old_version_file_id", this.oldactivefile || "");
      // related to aborting the request
      // console.log("this.datahashtag",this.datahashtag);
      if(this.datahashtag !== "" || this.datahashtag !== null || this.datahashtag !== undefined){
        for(let i = 0; i < this.datahashtag.length; i++ ){
          formData.append("hashtag", this.datahashtag[i])
        }
      }else{
          formData.append("hashtag", "")
      }

      const CancelToken = this.axios.CancelToken;
      const source = CancelToken.source();
      if (!this.existingFiles.includes(file.name)) {
        let switch_url;
        // if (this.sendswitchapi == true) {
        //   switch_url = process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files_my_drive";
        // } else {
        //   // ค่า default
        //   switch_url = process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files_v2";
        // }

        // รับค่าจากหน้า dialog tagversion มาสับ api
        if (this.switchapiupload == "updatetag") {
          switch_url =
            process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files_tag";
        } else {
          // ค่า default
          switch_url =
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/upload_files_v2";
        }
        // the request itself
        let auth = await gbfGenerate.generateToken();
        this.axios({
          method: "POST",
          url: switch_url,
          cancelToken: source.token,
          data: formData,
          headers: { Authorization: auth.code },

          onUploadProgress: e => {
            // updating progress indicator
            // console.log('1_' + e.lengthComputable)
            // console.log('2_'+ e.loaded)
            // console.log( '3_'+ e.total)
            if ((e.loaded / e.total) * 100 >= 95) {
              progress(e.lengthComputable, (e.total / 100) * 99, e.total);
            } else {
              progress(e.lengthComputable, e.loaded, e.total);
            }
          }
        })
          .then(response => {
            console.log(response);
            // passing the file id to FilePond
            if (response.data.status === "OK") {
              load();
            } else {
              this.handleError(error, {
                message: response.data.errorMessage,
                code: 500
              });
            }
            this.createprogress = false;
          })
          .catch(thrown => {
            // console.log(thrown.response.status)
            if (this.axios.isCancel(thrown)) {
              console.log("Request canceled", thrown.message);
            } else {
              // handle error
              // console.log(thrown.response.status)
              this.handleError(error, thrown);
            }
            this.createprogress = false;
          });

        // Setup abort interface
        return {
          abort: () => {
            this.createprogress = false;
            source.cancel("Operation canceled by the user.");
            abort();
          }
        };
      }
      // var self = this;
      // try {
      //   progress(true, 0, 1);
      //   AlbumsRepository.uploadPhotoToAlbum(
      //     this.albumId,
      //     file,
      //     metadata,
      //     progressCallback => {
      //       progress(true, progressCallback, 1);
      //       console.log("Progress ", progressCallback);
      //     },
      //     status => {
      //       // console.log("Status ", status);
      //     },
      //     url => {
      //       load(url);
      //       console.log("Upload complete at ", url);
      //     },
      //     errorCallback => {
      //       console.log("Upload error ", error);
      //       self.handleError(errorCallback);
      //     }
      //   );
      //   return {
      //     abort: () => {
      //       abort();
      //       // uploadTask.cancel();
      //     }
      //   };
      // } catch (e) {
      //   this.handleError(error, e);
      //   return {
      //     abort: () => {
      //       abort();
      //     }
      //   };
      // }
    },

    load(uniqueFileId, load, error) {
      // error();
    },

    fetch(url, load, error, progress, abort, headers) {
      error("Local files only");
    },

    restore(uniqueFileId, load, error, progress, abort, headers) {
      // error();
    },

    revert(uniqueFileId, load, error) {
      // TODO -> AlbumRepository delete photo
      load();
    },
    handleFilePondInit: function() {
      console.log("FilePond has initialized");
    },
    handleError(error, e) {
      console.log("Catch error ", e);
      switch (e) {
        case "storage/canceled":
          break;
        default:
          console.log(e);
          error(e);
      }
    },
    handleWarning(error) {
      console.log("Warning file ", file);
    },
  }
    
}
</script>
<style>

</style>